import React, { useState } from 'react';
import AppRouter from './pages/AppRouter';
import './styles/App.css';
import { AppWrapper } from './styles/styles';
import AppContext from './utils/Context/context';

function App() {
  const [esizeid, setEsizeid] = useState(0)
  const [code, setCode] = useState('');
  const [guestToken, setGuestToken] = useState('');
  const [results, setResults] = useState({ length: 0, width: 0 });
//  const serverEndpoint = 'https://aiva-esize-backend2.demo.gravity-group.ru/api/v1/'
  const serverEndpoint = 'https://api.e-size.io/api/v1/'


  return (
    <AppContext.Provider value={{ guestToken, setGuestToken, results, setResults, code, setCode, esizeid, setEsizeid, serverEndpoint }}>
      <AppWrapper>
        <AppRouter />
      </AppWrapper>
    </AppContext.Provider>
  );
}

export default App;
